<template>
	
	
	<div class="ucPanel">
		<div class="fl ctms_dataStaFlow">
			<ul>
				<li>合同名称：
					<select v-model="cnox" class="ucForm-control" @change="cnoChange">
						<option v-for="option in cnos" v-bind:key = "option.id" :value="option.id">
							{{option.cname}}
						</option>
					</select>
				</li>
					
				<li>合同编号：
							{{cno}}
				</li>
				<li>签订时间：{{signDate}}</li>
				<li>签订部门：{{gName}}</li>
			</ul>
		</div>
		<div class="fr ucForm ucForm-flow ucBtn-bg">
			<div class="ucForm-group" style="margin-right: 5px;">
				<label class="ucForm-label">合同编号</label> 
				<input  type="text"  class="ucForm-control" placeholder="请输入合同编号" v-model="cno" />
			</div>
			<div class="ucForm-group">
				<button class="ucBtn ucBtn-search ucBtn-green" @click="findContract()" ><i class="iImg"></i>查询</button>
			</div>
			<div class="ucForm-group">
				<button class="ucBtn ucBtn-add ucBtn-green"  @click="handleClickAdd()"><i class="iImg"></i>添加</button>
			</div>
		</div>
		<div class="clear"></div>
	</div>	
	<div class="ucPanel ucPanel-table">
		
			<el-table :data="dataList" highlight-current-row border style="width: 100%">
				<!-- <el-table-column prop="id" label="序号"  width="78px;"></el-table-column> -->
				<el-table-column prop="amount" label="收款金额/元" v-if="isshow"></el-table-column>
				<el-table-column prop="paydate" label="收款时间" v-if="isshow"></el-table-column>
				<el-table-column prop="amount" label="付款金额/元" v-if="isshow2"></el-table-column>
				<el-table-column prop="paydate" label="付款时间" v-if="isshow2"></el-table-column>
				<el-table-column prop="creatorName" label="操作人" ></el-table-column>
				<el-table-column prop="mark" label="备注" ></el-table-column>
				<el-table-column fixed="right" label="操作"  width="180px" >
					<template v-slot="scope" >
						<button class="ucBtn ucBtn-edit ucBtn-green" @click="handleClickEdit(scope.row)"><i class="iImg"></i>编辑</button>
						<button class="ucBtn ucBtn-del ucBtn-green" @click="handleClickDelete(scope.row)"><i class="iImg"></i>删除</button>
					</template>
				</el-table-column>
			</el-table>
			
			<ctms_contract_money_info @closecallinfo="closecallinfo"  v-if="centerDialogVisible" width="80%"></ctms_contract_money_info>
		
	</div>
</template>
<script>
	import ctms_contract_money_info from './ctms_contract_money_info.vue'
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	export default {
		name: 'ZhProvider',
		props: {
			msg: String
		},
		data: function() {
			return {
				centerDialogVisible: false,
				dataList: [],
				infoMod: "",
				editId: null,
				beginDate:'',
				endDate:'',
				activeName: 'first',
				cno:this.$route.params.cno,
				cname:'',
				signDate:'',
				gName:'',
				famount:0,
				isshow:true,
				isshow2:false,
				ctype:1,
				paydate:'',
				mark:'',
				amount:0,
				cid:null,
				cnos:[],
				cnox:''
			}
		},
		created() {
			// this.fetchData();
			this.findContract();
		},
		methods: {
			handleClickEdit: function(data){
				this.infoMod = "edit";
				this.editId = data.id;
				this.paydate = data.paydate;
				this.amount = data.amount;
				this.mark = data.mark;
				this.cid = data.cid;
				this.centerDialogVisible = true;
			},
			handleClickDelete: function(data){
				this.$confirm('您确定要删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'}).then(()=>{
						this.deleteData(data.id);
					});
			},
			handleClickAdd: function(){
				if(this.cname==null||this.cname==""){
					this.$message({
						message:'合同不存在，请勿添加',
						type:'error'
					})
				}else{
					this.infoMod = "add";
					this.centerDialogVisible = true;
				}
			},
			cnoChange: function(){
				this.dataList = [];
				var req = {};
				req.cmd = "searchRevenueAmount";
				if(this.cno==''||this.cno==null){
					this.$router.push({name:'ctms_c_jk',params:{cno:this.cno}});
				}
				req.id=this.cnox;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					this.dataList = res.datas.items;
					if(typeof res.datas.contract == "undefined"){
						this.cname=null;
						this.signDate=null;
						this.gName=null;
						this.famount=null;
						this.ctype=null;
					}else{
						this.cno=res.datas.contract.cno;
						this.cname=res.datas.contract.cname;
						this.signDate=res.datas.contract.signDate;
						this.gName=res.datas.contract.gName;
						this.famount=res.datas.contract.amount;
						this.ctype=res.datas.contract.ctype;
					}
					if(this.ctype==1){
						this.isshow=true;
						this.isshow2=false;
					}else{
						this.isshow2=true;
						this.isshow=false;
					}
				}, null, this);	
			},
			findContract:function() {
				var req = {};
				req.cmd = "searchContractByCnoForRevenueAmount";
				if(this.cno==''||this.cno==null){
					this.$router.push({name:'ctms_c_jk',params:{cno:this.cno}});
				}
				req.cno=this.cno;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					this.dataList = res.datas.items;
					this.cnos=res.datas.contract;
					if(typeof res.datas.contract == "undefined"||res.datas.contract.length<1){
						this.cname=null;
						this.signDate=null;
						this.gName=null;
						this.famount=null;
						this.ctype=null;
					}else{
						this.cno=res.datas.contract[0].cno;
						this.cnox=res.datas.contract[0].id;
						this.cname=res.datas.contract[0].cname;
						this.signDate=res.datas.contract[0].signDate;
						this.gName=res.datas.contract[0].gName;
						this.famount=res.datas.contract[0].amount;
						this.ctype=res.datas.contract[0].ctype;
					}
					if(this.ctype==1){
						this.isshow=true;
						this.isshow2=false;
					}else{
						this.isshow2=true;
						this.isshow=false;
					}
					if(this.cname==null||this.cname==""){
						this.$message({
							message:'编号为【'+this.cnos+'】的合同不存在',
							type:'error'
						})
					}
				}, null, this);	
			},
			deleteData: function(id){
				this.dataList = [];
				var req = {};
				req.cmd = "deleteRevenueAmount";
				req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					this.findContract();
				}, null, this);	
			},
			closecallinfo: function() {
				this.centerDialogVisible = false;
				this.editId = null;
				this.paydate = '';
				this.amount = 0;
				this.mark = '';
				this.cid = null;
				this.cnoChange();
			}
		},
		watch: {
		},
		components: {
			ctms_contract_money_info
		},
		mounted: function() {
			
		}
	}
</script>